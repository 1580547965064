import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Polygon, FeatureGroup } from "react-leaflet";
import { map } from "leaflet";
import "./MapEditor.css";
function MapEditor() {
  let { slug, id } = useParams();
  const [mapData, setMapData] = useState(null);
  const [newPerson, setNewPerson] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(async () => {
    const result = await fetch(`https://mapa.helb.cz/api/map/${slug}/${id}`)
      .then(async (r) => {
        const json = await r.json();
        if (json.error) {
          setErrorMsg(json.error);
          return;
        }
        return json;
      })
      .catch((e) => setErrorMsg(e.toString()));

    if (result) {
      setErrorMsg(null);
      setMapData(result);
      document.title = `EDIT: ${result.name}`;
    }
  }, []);

  async function postChanges() {
    const result = await fetch(`https://mapa.helb.cz/api/map/${slug}/${id}`, {
      method: "POST",
      body: JSON.stringify({
        ...mapData,
      }),
    }).then((r) => r.json());
    document.title = `EDIT: ${result.name}`;
    setMapData(result);
  }

  return (
    <div>
      {mapData && (
        <MapContainer
          key={[
            mapData.default_coords_x,
            mapData.default_coords_y,
            mapData.default_zoom,
          ]}
          center={[mapData.default_coords_x, mapData.default_coords_y]}
          zoom={mapData.default_zoom}
          scrollWheelZoom={false}
          style={{ height: "33vh", width: "100vw" }}
          minZoom={3}
          zoomControl={false}
        >
          <TileLayer url={mapData.tiles_url} key={mapData.tiles_url} />
        </MapContainer>
      )}
      {mapData && (
        <form>
          <label>
            <span>Název mapy:</span>
            <input
              type="text"
              value={mapData.name}
              onChange={(e) =>
                setMapData({
                  ...mapData,
                  name: e.target.value,
                })
              }
            />
          </label>
          <label>
            <span>Odkaz:</span>
            <input
              type="text"
              value={mapData.link}
              onChange={(e) =>
                setMapData({
                  ...mapData,
                  link: e.target.value,
                })
              }
            />
          </label>
          <label>
            <span>Logo (URL):</span>
            <input
              type="text"
              value={mapData.logo_url}
              onChange={(e) =>
                setMapData({
                  ...mapData,
                  logo_url: e.target.value,
                })
              }
            />
          </label>
          <label>
            <span>Barva aktivní oblasti:</span>
            <input
              type="color"
              value={mapData.color_active}
              onChange={(e) =>
                setMapData({
                  ...mapData,
                  color_active: e.target.value,
                })
              }
            />
          </label>
          <label>
            <span>Barva neaktivní oblasti:</span>
            <input
              type="color"
              value={mapData.color_inactive}
              onChange={(e) =>
                setMapData({
                  ...mapData,
                  color_inactive: e.target.value,
                })
              }
            />
          </label>
          <label>
            <span>Výchozí zoom:</span>
            <input
              type="number"
              value={mapData.default_zoom}
              min={3}
              max={18}
              onChange={(e) =>
                setMapData({
                  ...mapData,
                  default_zoom: e.target.value,
                })
              }
            />
          </label>
          <label>
            <span>Výchozí souřadnice:</span>
            <input
              type="number"
              value={mapData.default_coords_x}
              onChange={(e) =>
                setMapData({
                  ...mapData,
                  default_coords_x: e.target.value,
                })
              }
            />
            <input
              type="number"
              value={mapData.default_coords_y}
              onChange={(e) =>
                setMapData({
                  ...mapData,
                  default_coords_y: e.target.value,
                })
              }
            />
          </label>
          <label>
            <span>Podkladová mapa:</span>
            <select
              value={mapData.tiles_url}
              onChange={(e) =>
                setMapData({
                  ...mapData,
                  tiles_url: e.target.value,
                })
              }
            >
              {mapData.tile_urls.map((item) => (
                <option value={item.url} key={item.url}>
                  {item.name}
                </option>
              ))}
            </select>
          </label>
          <label>
            <span>Text:</span>
            <textarea
              value={mapData.notes}
              onChange={(e) =>
                setMapData({
                  ...mapData,
                  notes: e.target.value,
                })
              }
            />
          </label>
          <label>
            <span>Pozvánky:</span>
            <input
              type="checkbox"
              checked={mapData.invites_enabled}
              onChange={(e) =>
                setMapData({
                  ...mapData,
                  invites_enabled: e.target.checked,
                })
              }
            />
            {mapData.invites_enabled && (
              <input
                type="text"
                readOnly={true}
                value={`${document.location.origin}/i/${slug}/${mapData.invite_token}`}
              />
            )}
          </label>
        </form>
      )}
      {mapData && (
        <table>
          <thead>
            <tr>
              <th>Jméno</th>
              <th>Odkaz na profil</th>
              <th>Odkaz na editaci mapy</th>
              <th>Zobrazovat</th>
            </tr>
          </thead>
          <tbody>
            {mapData.persons.map((person) => (
              <tr key={person.name}>
                <td>{person.name}</td>
                <td>
                  <a href={person.link}>{person.link}</a>
                </td>
                <td>
                  {person.id && (
                    <input
                      type="text"
                      readOnly={true}
                      value={`${document.location.origin}/p/${person.id}/${person.token}`}
                    />
                  )}
                </td>
                <td>
                  {person.id && (
                    <input
                      type="checkbox"
                      checked={person.is_enabled}
                      onChange={(e) => {
                        setMapData({
                          ...mapData,
                          persons: mapData.persons.map((p) => ({
                            ...p,
                            is_enabled:
                              p.id === person.id
                                ? e.target.checked
                                : p.is_enabled,
                          })),
                        });
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={4}>
                <input
                  type="text"
                  value={newPerson}
                  onChange={(e) => setNewPerson(e.target.value)}
                />
                <button
                  onClick={(e) => {
                    setMapData({
                      ...mapData,
                      persons: [...mapData.persons, { name: newPerson }],
                    });
                    setNewPerson("");
                  }}
                >
                  +
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      )}

      <button onClick={postChanges}>Uložit</button>
    </div>
  );
}

export default MapEditor;
