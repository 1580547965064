import EditControl from "./EditControl";
import isEqual from "lodash.isequal";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "./Map.css";
import "./PersonEditor.css";
import { MapContainer, TileLayer, Polygon, FeatureGroup } from "react-leaflet";
import React from "react";
import { withRouter } from "react-router";
const getPolygon = (latlngs) => ({
  shape: latlngs[0].map((o) => [o.lat, o.lng]),
});

class PersonEditor extends React.Component {
  constructor() {
    super();
    this.state = {
      personData: undefined,
      polygonsToDelete: [],
      sidebar: true,
      errorMsg: null,
      saveSuccess: null,
    };
    this.postChanges = this.postChanges.bind(this);
  }

  async postChanges() {
    this.setState({ saveSuccess: null });
    const { id, token } = this.props.match.params;
    const result = await fetch(
      `https://mapa.helb.cz/api/person/${id}/${token}`,
      {
        method: "POST",
        body: JSON.stringify({
          ...this.state.personData,
          polygonsToDelete: this.state.polygonsToDelete,
        }),
      }
    )
      .then(async (r) => {
        const json = await r.json();
        if (json.error) {
          this.setState({ errorMsg: json.error });
          return;
        }
        return json;
      })
      .catch((e) => this.setState({ errorMsg: e.toString() }));
    if (result) {
      document.title = `EDIT: ${result.name} (${result.map.name})`;
      this.setState({ errorMsg: null, personData: result, saveSuccess: true });
      setTimeout(() => this.setState({ saveSuccess: null }), 1000);
    }
  }

  async componentDidMount() {
    const { id, token } = this.props.match.params;

    const result = await fetch(
      `https://mapa.helb.cz/api/person/${id}/${token}`
    ).then((r) => r.json());
    document.title = `EDIT: ${result.name} (${result.map.name})`;
    this.setState({ personData: result });
  }

  render() {
    return (
      <>
        {this.state.errorMsg && (
          <div className="error">{this.state.errorMsg}</div>
        )}
        <div className="flex">
          {this.state.personData && (
            <MapContainer
              key={[
                this.state.personData.map.default_coords_x,
                this.state.personData.map.default_coords_y,
                this.state.personData.map.default_zoom,
                this.state.personData.map.tiles_url,
                this.state.sidebar,
              ]}
              center={[
                this.state.personData.map.default_coords_x,
                this.state.personData.map.default_coords_y,
              ]}
              zoom={this.state.personData.map.default_zoom}
              scrollWheelZoom={true}
              style={{
                height: "100vh",
                width: this.state.sidebar ? "calc(100vw - 280px)" : "100vw",
              }}
              minZoom={3}
            >
              <TileLayer url={this.state.personData.map.tiles_url} />
              <FeatureGroup key={this.state.personData.polygons}>
                <EditControl
                  position="topright"
                  onCreated={(e) => {
                    this.setState({
                      personData: {
                        ...this.state.personData,
                        polygons: [
                          ...this.state.personData.polygons,
                          getPolygon(e.layer._latlngs),
                        ],
                      },
                    });
                  }}
                  onDeleted={(e) => {
                    const toDelete = [];
                    Object.keys(e.layers._layers).forEach((key) => {
                      const deletedPolygon = getPolygon(
                        e.layers._layers[key].editing.latlngs[0]
                      );
                      const idToDelete = this.state.personData.polygons.filter(
                        (polygon) =>
                          isEqual(polygon.shape, deletedPolygon.shape)
                      )[0].id;
                      toDelete.push(idToDelete);
                    });
                    this.setState({
                      polygonsToDelete: [
                        ...this.state.polygonsToDelete,
                        ...toDelete,
                      ],
                    });
                  }}
                  draw={{
                    rectangle: false,
                    circlemarker: false,
                    polyline: false,
                    marker: false,
                    circle: false,
                  }}
                  edit={{
                    edit: false,
                  }}
                />
                {this.state.personData.polygons.map((polygon) => (
                  <Polygon
                    pathOptions={{
                      color: this.state.personData.map.color_inactive,
                      weight: 1,
                      fillColor: this.state.personData.map.color_inactive,
                      fillOpacity: 0.3,
                    }}
                    key={polygon.id || polygon.shape}
                    positions={polygon.shape}
                  />
                ))}
              </FeatureGroup>
            </MapContainer>
          )}
          <button
            className={`sidebar-toggle ${
              this.state.sidebar ? "opened" : "closed"
            }`}
            onClick={(e) => this.setState({ sidebar: !this.state.sidebar })}
          >
            {this.state.sidebar ? "▶️" : "◀️"}
          </button>
          {this.state.sidebar && (
            <div className="sidebar" style={{ width: "280px" }}>
              {this.state.personData && (
                <div>
                  <label>
                    <span>Jméno:</span>
                    <input
                      type="text"
                      value={this.state.personData.name}
                      onChange={(e) =>
                        this.setState({
                          personData: {
                            ...this.state.personData,
                            name: e.target.value,
                          },
                        })
                      }
                    />
                  </label>
                  <label>
                    <span>Odkaz na profil:</span>
                    <input
                      type="text"
                      value={this.state.personData.link}
                      onChange={(e) =>
                        this.setState({
                          personData: {
                            ...this.state.personData,
                            link: e.target.value,
                          },
                        })
                      }
                    />
                  </label>
                  <label>
                    <span>Text:</span>
                    <textarea
                      value={this.state.personData.notes}
                      onChange={(e) =>
                        this.setState({
                          personData: {
                            ...this.state.personData,
                            notes: e.target.value,
                          },
                        })
                      }
                    />
                  </label>
                </div>
              )}
              <button onClick={this.postChanges}>Uložit</button>
              {this.state.saveSuccess && (
                <div className="save-success">✔️ Uloženo</div>
              )}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(PersonEditor);
