import {
  MapContainer,
  TileLayer,
  Polygon,
  FeatureGroup,
  useMapEvents,
} from "react-leaflet";
import { isMobile, isMobileOnly } from "react-device-detect";
import "leaflet/dist/leaflet.css";
import "./Map.css";
import { useState, useEffect, useRef } from "react";
import pointInPolygon from "point-in-polygon";
import { useParams } from "react-router-dom";

const stripLink = (url) => url.replace(/^https?:\/\/(www\.)?/, "");

function Map() {
  let { slug } = useParams();
  const [mapData, setMapData] = useState({
    default_coords_x: 0,
    default_coords_y: 0,
    default_zoom: 8,
    tiles_url: null,
    polygons: [],
    persons: [],
  });
  const [currentZoom, setCurrentZoom] = useState(null);
  const [highlightedPolygons, setHighlightedPolygons] = useState([]);
  const [personCards, setPersonCards] = useState([]);
  // const [tooltipPersons, setTooltipPersons] = useState([]);
  const highlightedGroup = useRef();
  const cardsRef = useRef();
  const [map, setMap] = useState(null);
  const [sidebar, setSidebar] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(async () => {
    const result = await fetch(`https://mapa.helb.cz/api/map/${slug}`)
      .then(async (r) => {
        const json = await r.json();
        if (json.error) {
          setErrorMsg(json.error);
          return;
        }
        return json;
      })
      .catch((e) => setErrorMsg(e.toString()));

    if (result) {
      setErrorMsg(null);
      setMapData(result);
      setCurrentZoom(result.default_zoom);
      document.title = result.name;
    }
  }, []);

  function getNamesAtCoords(e) {
    const { lat, lng } = e.latlng;
    const polygonsAtCoords = mapData.polygons.filter((polygon) =>
      pointInPolygon([lat, lng], polygon.shape)
    );
    const persons = mapData.persons.filter(
      (p) =>
        [...new Set(polygonsAtCoords.map((polygon) => polygon.person))].indexOf(
          p.id
        ) >= 0
    );
    if (e.type === "click") {
      setPersonCards(persons);
      cardsRef.current.scrollLeft = 0;
      setHighlightedPolygons(polygonsAtCoords);
      if (persons.length > 0) {
        isMobile && setSidebar(false);
        fitHighlighted();
      }
    }
    if (!isMobile && e.type === "mousemove") {
      setHighlightedPolygons(polygonsAtCoords);
      // setTooltipPersons(persons.map((p) => p.name));
    }
  }

  function fitHighlighted() {
    setTimeout(() => {
      const bounds = highlightedGroup.current.getBounds();
      if (bounds._southWest && bounds._northEast) {
        map.fitBounds(bounds, {
          paddingTopLeft: [10, 10],
          paddingBottomRight: [!isMobile && sidebar ? 290 : 10, 160],
        });
      }
    }, 150);
  }

  function ClickHandler() {
    useMapEvents({
      mousemove: getNamesAtCoords,
      click: getNamesAtCoords,
      zoomend: (e) => setCurrentZoom(e.target._zoom),
    });
    return null;
  }

  return (
    <>
      {errorMsg && <div className="error">{errorMsg}</div>}
      <div>
        <MapContainer
          key={[
            mapData.default_coords_x,
            mapData.default_coords_y,
            mapData.default_zoom,
            mapData.tiles_url,
          ]}
          center={[mapData.default_coords_x, mapData.default_coords_y]}
          zoom={mapData.default_zoom}
          scrollWheelZoom={true}
          style={{
            height: "100vh",
            width: "100vw",
          }}
          minZoom={3}
          maxZoom={12}
          whenCreated={setMap}
        >
          {mapData.tiles_url && <TileLayer url={mapData.tiles_url} />}
          <ClickHandler />
          <FeatureGroup key={currentZoom}>
            {mapData.polygons
              .filter(
                (polygon) =>
                  highlightedPolygons
                    .map((polygon) => polygon.id)
                    .indexOf(polygon.id) < 0
              )
              .map((polygon) => (
                <Polygon
                  pathOptions={{
                    weight: 0,
                    fillColor: mapData.color_inactive,
                    fillOpacity: 0.2,
                  }}
                  key={polygon.id}
                  positions={polygon.shape}
                  className={`blur blur-${currentZoom}`}
                />
              ))}
          </FeatureGroup>
          <FeatureGroup ref={highlightedGroup} key={highlightedPolygons}>
            {highlightedPolygons.map((polygon) => (
              <Polygon
                pathOptions={{
                  weight: 0,
                  fillColor: mapData.color_active,
                  fillOpacity: 0.6,
                }}
                key={polygon.id}
                positions={polygon.shape}
                className={`blur blur-${currentZoom}`}
              />
            ))}
          </FeatureGroup>
        </MapContainer>
        <button
          className={`sidebar-toggle ${sidebar ? "opened" : "closed"}`}
          onClick={(e) => setSidebar(!sidebar)}
        >
          {sidebar ? "▶️" : "◀️"}
        </button>
        {sidebar && (
          <div className="sidebar">
            <div className="map-header">
              <h3 className="map-name">{mapData && mapData.name}</h3>
              {mapData && mapData.link && (
                <a className="map-link" href={mapData.link}>
                  {stripLink(mapData.link)}
                </a>
              )}
              {mapData && mapData.notes && (
                <p className="map-text">{mapData.notes}</p>
              )}
            </div>
            <div className="person-list">
              {mapData &&
                mapData.persons.map((person) => (
                  <p
                    key={person.id}
                    onClick={() => {
                      setHighlightedPolygons(
                        mapData.polygons.filter(
                          (polygon) => polygon.person === person.id
                        )
                      );
                      isMobile && setSidebar(false);
                      setPersonCards([person]);
                      cardsRef.current.scrollLeft = 0;
                      fitHighlighted();
                    }}
                  >
                    {person.name}
                  </p>
                ))}
            </div>
          </div>
        )}
        {mapData && (
          <div
            className="person-cards"
            style={{
              maxWidth: "100vw",
            }}
            ref={cardsRef}
          >
            {personCards.map((p) => (
              <div
                className="person-card"
                key={p.id}
                style={{
                  width: isMobile ? "calc(100vw - 2em)" : "calc(20vw - 0.5em)",
                }}
              >
                <header>{p.name}</header>
                {p.link && <a href={p.link}>{stripLink(p.link)}</a>}
                {p.notes && <p className="text">{p.notes}</p>}
              </div>
            ))}
          </div>
        )}
        {/* {!isMobile && tooltipPersons.length > 0 && (
          <div className="person-tooltip">
            {tooltipPersons.map((p) => (
              <span key={p} style={{ backgroundColor: mapData.color_active }}>
                {p}
              </span>
            ))}
          </div>
        )} */}
      </div>
    </>
  );
}

export default Map;
