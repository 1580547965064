import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import "./Invitation.css";

function Invitation() {
  let { slug, invite_token } = useParams();
  const history = useHistory();
  const [mapData, setMapData] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [personData, setPersonData] = useState({
    name: "",
    link: "",
  });

  useEffect(async () => {
    const result = await fetch(`https://mapa.helb.cz/api/map/${slug}`)
      .then(async (r) => {
        const json = await r.json();
        if (json.error) {
          setErrorMsg(json.error);
          return;
        }
        return json;
      })
      .catch((e) => setErrorMsg(e.toString()));

    if (result) {
      setErrorMsg(null);
      setMapData(result);
      document.title = `${result.name}`;
    }
  }, []);

  async function postPerson() {
    const result = await fetch(
      `https://mapa.helb.cz/api/person/${invite_token}`,
      {
        method: "POST",
        body: JSON.stringify(personData),
      }
    )
      .then(async (r) => {
        const json = await r.json();
        if (json.error) {
          setErrorMsg(json.error);
          return;
        }
        return json;
      })
      .catch((e) => setErrorMsg(e.toString()));

    if (result) {
      setErrorMsg(null);
      history.push(`/p/${result.id}/${result.token}`);
    }
  }

  return (
    <>
      {errorMsg && <div className="error">{errorMsg}</div>}
      <div className="invitation">
        {mapData && mapData.invites_enabled && (
          <div className="wrapper">
            <h3>{mapData.name}</h3>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                postPerson();
              }}
            >
              <input
                type="text"
                value={personData.name}
                placeholder="Jméno"
                required={true}
                onChange={(e) =>
                  setPersonData({ ...personData, name: e.target.value })
                }
              />
              <input
                type="text"
                value={personData.link}
                placeholder="Odkaz na profil"
                required={true}
                onChange={(e) =>
                  setPersonData({ ...personData, link: e.target.value })
                }
              />
              <button>Přidat</button>
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default Invitation;
