import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Map from "./Map";
import PersonEditor from "./PersonEditor";
import MapEditor from "./MapEditor";
import Invitation from './Invitation';

function App() {
  return (
    <Switch>
      <Route path="/p/:id/:token">
        <PersonEditor />
      </Route>
      <Route path="/m/:slug/:id">
        <MapEditor />
      </Route>
      <Route path="/i/:slug/:invite_token">
        <Invitation />
      </Route>
      <Route path="/:slug">
        <Map />
      </Route>
      <div className="error">Mapa nenalezena.</div>
    </Switch>
  );
}

export default App;
